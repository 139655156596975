import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
// import "./Dashboard.css";
import { auth, logout } from "../MyComponents/ServerWorks/firebase";

import Header from "./Header";
import { LeftSide } from "./LeftSide";
import { Footer } from "./Footer";
import Login from "./Users/Login";
import {
  getIsUserInfo,
  getLoginCalled,
  setIsUserInfo,
  setLoginCalled,
  userDetail,
} from "./Variables/TextVariables";
import SignupPage from "./Users/signupPage";
import EditProfile from "./Users/EditProfile";

function Dashboard(props) {
  const [user, loading, error] = useAuthState(auth);
  const [showEditProfile, setShowEditProfile] = useState(false); // State to toggle EditProfile

  // const [isLoginCalled, setIsLoginCalled] = useState(getLoginCalled);

  const handleLogout = () => {
    console.log("Logout called");

    // setIsLoginCalled(false)
    logout();
  };
  const handleProfile = () => {
    console.log("Profile called");
    setShowEditProfile(!showEditProfile);
  };
  // console.log("isLoginCalled:", isLoginCalled)

  useEffect(() => {
    console.log("inside useEffect");
    if (loading) return;

    if (!user) return () => <Login />;
    console.log("user is present here");
    // fetchUserName();
  }, [user, loading]);
  // if (!user) return <Login />
  const [selection, setSelection] = useState("सचिवालय");
  if (!getIsUserInfo()) return <SignupPage />;
  // else alert("userInfo:", getIsUserInfo())
  return (
    <div className="dashboard">
      {getIsUserInfo()}
      <div className="dashboard_container">
        <Header
          selection={selection}
          title={`Sifaris|${userDetail().office.नाम}`}
          searchBar={false}
          user={user}
          logout={() => handleLogout()}
          profile={() => handleProfile()}
        />
        {/* 
                {!getIsUserInfo() ? <div className="center"><SignupPage /></div> :
                    (user.email ?  */}
        <>
          {showEditProfile && ( // Conditionally render EditProfile
            <EditProfile />
          )}

          <LeftSide selection={selection} setSelection={setSelection} />
          <Footer />
        </>
        {/* )                } */}
      </div>
    </div>
  );
}
export default Dashboard;
