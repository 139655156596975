import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import OfficeInformation from "../ParagraphItems/OfficeInformation";
import Sender from "../ParagraphItems/Sender";
import { bold, spaces, underline } from "../Variables";
import { getApplicant } from "../Variables/TextVariables";
import OfficeInformationEnglish from "../ParagraphItems/OfficeInformationEnglish";
import { getAD } from "../tests";
import { tab } from "@testing-library/user-event/dist/tab";

export default function NataPramanit(prop) {
  const props = prop.prop;
  const details = props.details;
  const language = details.language ?? "नेपाली";
  const isEnglish = language === "English" ? true : false;
  const prayojan = details.प्रयोजन
    ? details.प्रयोजन
    : !isEnglish
    ? "विशेष"
    : "Special";
  const inRelations = details.inRelations
    ? details.inRelations
    : [{ नाता: "", नाम: "" }];
  const [count, setCount] = useState(inRelations.length);
  console.log({ count });
  // props.detailsUpdater("प्रयोजन", prayojan);
  // props.detailsUpdater("inRelations", inRelations)

  const prayojanInput = (
    <input
      type="textarea"
      name="prayojan"
      id="prayojan"
      value={prayojan}
      onChange={(e) => props.detailsUpdater("प्रयोजन", e.target.value)}
    />
  );
  const photoName = isEnglish ? "Photo" : "फोटो";
  const languageInput = (
    <div>
      <label>
        <input
          type="radio"
          name="language"
          value="नेपाली"
          checked={language === "नेपाली"}
          onChange={(e) => props.detailsUpdater("language", e.target.value)}
        />
        नेपाली
      </label>
      <label style={{ marginLeft: "15px" }}>
        <input
          type="radio"
          name="language"
          value="English"
          checked={language === "English"}
          onChange={(e) => props.detailsUpdater("language", e.target.value)}
        />
        English
      </label>
    </div>
  );
  var personsInput = [];
  let personsCounts = 0; //added persons
  const addPersonsButton = (
    <button
      className="btn btn-primary btn-lg"
      onClick={(e) => {
        setCount(count + 1);
      }}
    >
      ADD{" "}
    </button>
  );
  while (count > personsCounts) {
    let personsCount = personsCounts;
    let x = inRelations[personsCount]
      ? ""
      : inRelations.push({ नाता: "", नाम: "" });
    personsInput.push(
      <div className="row" key={personsCount}>
        <div className="col-md-4">
          {" "}
          <input
            type="text"
            name="person"
            id="person"
            value={inRelations[personsCount].नाम}
            placeholder="व्यक्तिको नाम"
            onChange={(e) => {
              let currentObject = inRelations[personsCount];
              console.log(personsCount, "personsCount");
              currentObject["नाम"] = e.target.value;
              console.log("inRelations From Inside", inRelations);
              props.detailsUpdater("inRelations", [...inRelations]);
            }}
          />
        </div>
        {spaces(2)}
        <div className="col-md-4 ">
          {" "}
          <input
            type="text"
            name="person"
            id="person"
            value={inRelations[personsCount].नाता}
            placeholder="निवेदक सँग नाता"
            onChange={(e) => {
              let currentObject = inRelations[personsCount];
              currentObject["नाता"] = e.target.value;
              props.detailsUpdater("inRelations", [...inRelations]);
            }}
          />
        </div>
        <div className="col">
          {spaces(2)}
          <button
            className="col btn btn-outline-danger btn-sm"
            onClick={(e) => {
              inRelations.splice(personsCount, 1);
              // setInRelations([...inRelations]);
              setCount(count - 1);
            }}
          >
            Remove
          </button>
        </div>
      </div>
    );
    ++personsCounts;
  }
  if (personsCounts === 0) {
  }
  const tablehead = (
    <>
      <th>क्र.सं.</th>
      <th>नाम</th>
      <th>नाता</th>
    </>
  );
  // make english tablehead variable
  const tablehead_english = (
    <>
      <th>S.N.</th>
      <th>Name</th>
      <th>Relation</th>
    </>
  );
  const tablebody = inRelations.map((items, index) => {
    console.log(index, items["नाम"] ? true : false);

    return index === 0 ? (
      <tr key={index}>
        <td>{1}</td>
        <td>{capitalizeEachWord(props.applicant.Name)}</td>
        <td>{isEnglish ? "Applicant" : "निवेदक"}</td>
        <td>{index + 2}</td>
        <td>{capitalizeEachWord(items["नाम"])}</td>
        <td>{capitalizeEachWord(items["नाता"])}</td>
      </tr>
    ) : index % 2 === 0 ? (
      ""
    ) : (
      <tr key={index}>
        <td>{index + 2}</td>
        <td>{capitalizeEachWord(items["नाम"])}</td>
        <td>{capitalizeEachWord(items["नाता"])}</td>
        {inRelations[index + 1] ? (
          <>
            <td>{index + 3}</td>
            <td>{capitalizeEachWord(inRelations[index + 1]["नाम"])}</td>
            <td>{capitalizeEachWord(inRelations[index + 1]["नाता"])}</td>
          </>
        ) : (
          ""
        )}
      </tr>
    );
  });
  function capitalizeEachWord(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  const applicantphoto = (
    <td>
      <div> {props.applicant.Name}</div>
      <div className="rect">{photoName}</div>
    </td>
  );
  let photobody = [
    <td align="center" className="nataBoxes">
      <div className="nataName">{capitalizeEachWord(props.applicant.Name)}</div>
      <div className="rect">{photoName}</div>
    </td>,
  ];

  inRelations.map((items, index) => {
    photobody.push(
      <td key={index} align="center" className="nataBoxes">
        <div className="nataName">{capitalizeEachWord(items["नाम"])}</div>

        <div className="rect">
          {!isEnglish && (items["नाम"].startsWith("स्व.") ? "मृतक" : photoName)}
          {isEnglish &&
            (items["नाम"].startsWith("Late") ? "Deceased" : photoName)}
        </div>
      </td>
    );
    return null;
  });
  const columnWidth =
    photobody.length > 0 && photobody.length < 5
      ? `calc(100% / ${photobody.length})` // Dynamic width for 1 to 4 items
      : "calc(100% / 4)";
  const tableMargin = photobody.length < 5 ? "10%" : "0%";
  const phototable = (
    <>
      {/* <tr >{photohead}</tr> */}
      <tr>
        {photobody.map((body, index) => (
          <td
            key={index}
            align="center"
            className="nataBoxes"
            style={{
              width: columnWidth,
              marginBottom: tableMargin,
              border: "none",
            }}
          >
            {body}
          </td>
        ))}
      </tr>
    </>
  );
  const componentRef = props.componentRef;
  return (
    <div>
      {languageInput}
      <br />
      प्रयोजनः {prayojanInput} {spaces(10)}
      {addPersonsButton}
      <br />
      <br />
      नाता प्रमाणित गर्न चाहेका व्यक्तिहरु <br />
      {personsInput}
      <br />
      {!isEnglish && (
        <div
          className="businessClose"
          ref={componentRef}
          contentEditable={true}
        >
          <OfficeInformation />
          <div id="DateRow" align="right">
            {" "}
            मितिः {props.date.currentDate}
          </div>

          <h3 align="center">विषयः {underline("नाता प्रमाणित")} ।</h3>
          <h4 align="center">यो जो जससँग सम्बन्धित छ ।</h4>

          <div align="justify">
            {bold(getApplicant())}ले तपसिलमा उल्लिखित व्यक्तिहरु बीच{" "}
            {bold(prayojan)} प्रयोजनका लागि नाता प्रमाणित गरिदिन यस वडा
            कार्यालयमा दिनु भएको निवेदन अनुसार निजहरुबीच देहाय बमोजिम नाता रहेको
            व्यहोरा स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२ (२) ङ (१) बमोजिम
            प्रमाणित गरिन्छ ।
            <br />
            {underline("तपसिल")}
            <table>
              <thead>
                <tr>
                  {tablehead}
                  {tablehead}
                </tr>
              </thead>
              <tbody align="left">
                {console.log(tablebody, "tablebody")}
                {tablebody}
              </tbody>
            </table>
            <br />
            <table>
              <tbody>{phototable}</tbody>
            </table>
            निवेदक को औठा छाप
            <div className="row left ">
              <div className="rect block"></div>
              <div className="rect block"></div>

              <div className="col">
                <div className="signature" align="right">
                  <br />
                  <br />
                  <br />
                  <br />
                  <Sender person={props.person} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isEnglish && (
        <div className="englishDiv" ref={componentRef} contentEditable={true}>
          <OfficeInformationEnglish date={props.date} />
          <h4 align="center">Subject {underline("Relation Certification")}</h4>
          <h5 align="center">To Whoever It Concerns.</h5>

          <div align="justify">
            In accordance with the application submitted to this ward office
            requesting the certification of the relationship among the following
            mentioned individuals (whose photos are attached herewith), it is
            hereby certified that the relationship between them is as follows,
            pursuant to Section 3 clause 12 (2) (E) (1) of the Local Government
            Operation Act, 2074.
            <br />
            <table>
              <thead>
                <tr>
                  {tablehead_english}
                  {tablehead_english}
                </tr>
              </thead>
              <tbody align="left">
                {console.log(tablebody, "tablebody")}
                {tablebody}
              </tbody>
            </table>
            <br />
            <table style={{ border: "none" }}>
              <tbody>{phototable}</tbody>
            </table>
            <u> Applicant's Thumbprint</u>
            <div className="row ">
              <div className="col">
                <table
                  style={{
                    width: "100%",
                    textAlign: "center",
                    borderCollapse: "collapse",
                    border: "none",
                  }}
                >
                  <tr style={{ border: "none" }}>
                    <td className="nataBoxes" style={{ border: "none" }}>
                      <div>Right</div>
                      <div className="rect block"></div>
                    </td>
                    <td className="nataBoxes" style={{ border: "none" }}>
                      <div>Left</div>
                      <div className="rect block"></div>
                    </td>
                  </tr>
                </table>
              </div>

              <div className="col">
                <div className="signature" align="right">
                  <br />
                  <br />
                  <br />
                  <br />
                  <Sender person={props.person} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
