import React from "react";
import { userDetails } from "../LoginDetails";
import nepal_logo from "../Logos/nepal_logo.jpg";
import OfficeFooter from "./OfficeFooter";
import { currentFiscalYear } from "../Variables/TextVariables";

export default function OfficeInformation(props) {
  const {
    chalaniName = "चलानी नं :-",
    patraName = "पत्र संख्या :- ",
    noPatra,
  } = props;
  let userDetail = userDetails();
  const patraShankya = `${patraName} ${currentFiscalYear()}`;

  console.log("ussseerrDDeetaaisl", userDetail);
  return (
    <div className="officeInformation">
      <div className="nepalLogo">
        <div className="logo">
          <img src={nepal_logo} alt="nepallogo" />
        </div>
        {props.noChalani ? (
          ""
        ) : (
          <React.Fragment>
            <p>
              {!props.noPatra && patraShankya} <br />
              {chalaniName}
            </p>
          </React.Fragment>
        )}
      </div>
      <div className="HeadingInfo">
        <br />
        <h2>
          {userDetail.municipality.नाम} {userDetail.municipality.प्रकार}
        </h2>
        {/* <h3>{userDetail.municipality.engName.toUpperCase()} {userDetail.municipality.engType.toUpperCase()}</h3> */}
        <h4>{userDetail.office.नाम}</h4>
        {/* <h4>{userDetail.office.engName}</h4> */}
        <h5>
          {userDetail.office.ठेगाना},{/*userDetail.municipality.नाम*/}{" "}
          {userDetail.district}
          <br />
          {userDetail.province}, नेपाल
        </h5>
      </div>
      <hr />
      {!props.headerName ? "" : <h3>{props.headerName}</h3>}
      {props.nofooter ? "" : <OfficeFooter />}
    </div>
  );
}
