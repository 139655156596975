import { dayCountInYears, getNepaliDate } from "../Date";
import { userDetails } from "../LoginDetails";
import { getAge } from "../tests";
const variables = require("../Variables");
export const currentDate = getNepaliDate();
var applicant = {};
var userInfo = userDetails();
export const userDetail = () => {
  return userInfo;
};
export const setuseDetailsInTextVariables = (details) => {
  userInfo = details;
  console.log("things changed", userInfo);
  return "";
};
export const setApplicant = (newApplicant) => {
  applicant = newApplicant;
  console.log(applicant);
  return "";
};
export const getApplicant = () => {
  if (applicant)
    if (applicant.Name) {
      let temp = "";
      let applicantCitizenship = "";
      const ko = applicant.gender ? variables.joiner[applicant.gender] : "को";
      const childCalling = applicant.gender
        ? variables.childCalling[applicant.gender]
        : "छोरा";
      const grandChildCalling = applicant.gender
        ? variables.grandChildCalling[applicant.gender]
        : "नाती";
      if (applicant.Father)
        temp = `श्री ${applicant.Father + ko} ${childCalling} `;
      if (applicant.Mother)
        temp = `श्रीमती ${applicant.Mother + ko} ${childCalling} `;
      if (applicant.Father && applicant.Mother)
        temp = `श्री ${applicant.Father} र श्रीमती ${
          applicant.Mother + ko
        } ${childCalling} `;
      if (applicant.Grandfather)
        temp = `श्री ${applicant.Grandfather + ko} ${grandChildCalling} `;
      if (applicant.Grandfather && applicant.Father)
        temp = `श्री ${applicant.Grandfather + ko} ${grandChildCalling} श्री ${
          applicant.Father + ko
        } ${childCalling}`;
      if (applicant.Grandfather && applicant.Mother)
        temp = `श्री ${
          applicant.Grandfather + ko
        } ${grandChildCalling} श्रीमती ${
          applicant.Mother + ko
        } ${childCalling}`;
      if (applicant.Grandfather && applicant.Mother && applicant.Father)
        temp = `श्री ${applicant.Grandfather + ko} ${grandChildCalling} श्री ${
          applicant.Father
        } र श्रीमती ${applicant.Mother + ko} ${childCalling}`;
      if (applicant.जन्ममिति)
        temp += " वर्ष " + getAge(applicant.जन्ममिति) + " " + ko;
      if (applicant.gender === variables.genderItems[0])
        temp += " श्रीमती " + applicant.Name;
      else temp += " श्री " + applicant.Name;
      if (applicant.Address) temp += " ," + applicant.Address;
      applicantCitizenship = applicant.नागरिकता;
      if (typeof applicantCitizenship !== "undefined")
        if (
          applicantCitizenship[variables.nagariktaDetails[0]] &&
          applicantCitizenship[variables.nagariktaDetails[1]] &&
          applicantCitizenship[variables.nagariktaDetails[2]]
        ) {
          temp +=
            " ना.प्र.नं " +
            applicantCitizenship[variables.nagariktaDetails[0]] +
            ", " +
            applicantCitizenship[variables.nagariktaDetails[1]] +
            ", " +
            applicantCitizenship[variables.nagariktaDetails[2]];
        }
      return temp;
    } else return "";
};
export const officeAddress = () => {
  return `${userDetail().province} ${userDetail().district} जिल्ला ${
    userDetail().municipality.नाम
  } ${userDetail().municipality.प्रकार} `;
};
export const getOtherPeoples = (otherPeople) => {
  let temp = applicant;
  applicant = otherPeople;
  let newPeople = getApplicant();
  applicant = temp;
  return newPeople;
};
export const applicantInfo = () => {
  return applicant;
};
var userInformationPresent = false;
export const setIsUserInfo = (isUserInfo) =>
  (userInformationPresent = isUserInfo);
export const getIsUserInfo = () => {
  return userInformationPresent;
};
var loginCalled = false;
export const setLoginCalled = (isLoginCalled) => (loginCalled = true);
export const getLoginCalled = () => {
  return loginCalled;
};
const nepaliNumbersOneToNinentyNine = {
  0: "सुन्ना",
  1: "एक",
  2: "दुई",
  3: "तीन",
  4: "चार",
  5: "पाँच",
  6: "छ",
  7: "सात",
  8: "आठ",
  9: "नौ",
  10: "दस",
  11: "एघार",
  12: "बाह्र",
  13: "तेह्र",
  14: "चौध",
  15: "पन्ध्र",
  16: "सोह्र",
  17: "सत्र",
  18: "अठार",
  19: "उन्नाइस",
  20: "बिस",
  21: "एक्काइस",
  22: "बाइस",
  23: "तेइस",
  24: "चौबिस",
  25: "पच्चिस",
  26: "छब्बिस",
  27: "सत्ताइस",
  28: "अट्ठाइस",
  29: "उनन्तिस",
  30: "तिस",
  31: "एकतिस",
  32: "बत्तिस",
  33: "तेत्तिस",
  34: "चौतिस",
  35: "पैँतिस",
  36: "छत्तिस",
  37: "सैँतिस",
  38: "अठतिस",
  39: "उनन्चालिस",
  40: "चालिस",
  41: "एकचालिस",
  42: "बयालिस",
  43: "त्रिचालिस",
  44: "चवालिस",
  45: "पैँतालिस",
  46: "छयालिस",
  47: "सतचालिस",
  48: "अठचालिस",
  49: "उनन्चास",
  50: "पचास",
  51: "एकाउन्न",
  52: "बाउन्न",
  53: "त्रिपन्न",
  54: "चवन्न",
  55: "पचपन्न",
  56: "छपन्न",
  57: "सन्ताउन्न",
  58: "अन्ठाउन्न",
  59: "उनसट्ठी",
  60: "साठी",
  61: "एकसट्ठी",
  62: "बयसट्ठी",
  63: "त्रिसट्ठी",
  64: "चौसट्ठी",
  65: "पैँसट्ठी",
  66: "छयसट्ठी",
  67: "सतसट्ठी",
  68: "अठसट्ठी",
  69: "उनन्सत्तरी",
  70: "सत्तरी",
  71: "एकहत्तर",
  72: "बहत्तर",
  73: "त्रिहत्तर",
  74: "चौहत्तर",
  75: "पचहत्तर",
  76: "छयहत्तर",
  77: "सतहत्तर",
  78: "अठहत्तर",
  79: "उनासी",
  80: "असी",
  81: "एकासी",
  82: "बयासी",
  83: "त्रियासी",
  84: "चौरासी",
  85: "पचासी",
  86: "छयासी",
  87: "सतासी",
  88: "अठासी",
  89: "उनान्नब्बे",
  90: "नब्बे",
  91: "एकान्नब्बे",
  92: "बयान्नब्बे",
  93: "त्रियान्नब्बे",
  94: "चौरान्नब्बे",
  95: "पन्चान्नब्बे",
  96: "छयान्नब्बे",
  97: "सन्तान्नब्बे",
  98: "अन्ठान्नब्बे",
  99: "उनान्सय",
};
const placevalues = ["", "सय", "हजार", "लाख", "करोड", "अर्ब", "खर्ब"];
export const convertNumberToText = (number) => {
  if (number === undefined || number === null) {
    return "";
  }
  if (number % 1 !== 0) {
    const [integerPart, decimalPart] = number.toString().split(".");
    const integerText = convertNumberToText(parseInt(integerPart));
    const decimalText = decimalPart
      .split("")
      .map((digit) => nepaliNumbersOneToNinentyNine[digit])
      .join(" ");
    return `${integerText} दशमलव ${decimalText} `;
  }
  let text = "";
  if (number >= 0 && number <= 99) {
    return (text += nepaliNumbersOneToNinentyNine[number] + " ");
  }
  for (let i = placevalues.length - 1; i >= 0; i--) {
    const multiplier = i == 1 ? 1 : i == 0 ? 1 : 0.1;
    const placeValue = Math.pow(100, i) * multiplier;
    if (number >= placeValue) {
      const num = Math.floor(number / placeValue);
      text += convertNumberToText(num) + " " + placevalues[i] + " ";
      number = number % placeValue;
    }
  }
  return text;
};
export function checkCharacters(inputString, allowedCharacters) {
  for (const character of inputString) {
    if (!allowedCharacters.includes(character)) return false;
  }
  return true;
}
export function isValidDate(v) {
  if (v.length < 1) return v;
  let currentYear = currentDate.substring(0, 4) * 1;
  let match = /^(\d{4})([-/]{0,1})(\d{0,2})([-/]{0,1})(\d{0,2})$/.exec(v);
  if (match) {
    const value = isValidYMD(
      match[1] * 1,
      match[3].length <= 1 ? -1 : match[3] * 1,
      match[5].length <= 1 ? -1 : match[5] * 1
    );
    // console.log(value);
    return value
      ? v.length == 8 && !match[2] && !match[4]
        ? match[1] + "/" + match[3] + "/" + match[5]
        : v
      : isValidDate(v.slice(0, -1));
  } else {
    if (v.length < 4 && !isNaN(v)) return v;
    return isValidDate(v.slice(0, -1));
  }
  function isValidYMD(year, month, day) {
    // console.log({ year, month, day });
    if (year >= 2000 && month > 0)
      console.log(
        "days in month",
        dayCountInYears[year][month * 1 - 1],
        dayCountInYears[year]
      );
    return (
      year <= currentYear &&
      (month === -1 || (month > 0 && month < 13)) &&
      (day === -1 ||
        (day > 0 && day <= 32
          ? year >= 2000
            ? day <= dayCountInYears[year][month * 1 - 1]
            : true
          : false))
    );
  }
}
export const fiscalYearsLists = () => {
  let currentYear = currentDate.substring(0, 4) * 1;
  let fiscalLists = [];
  for (let year = currentYear - 40; year <= currentYear; year++) {
    fiscalLists.unshift(`${year}/${"0" + ((year % 1000) + 1)}`);
  }
  return fiscalLists;
};
export const currentFiscalYear = () => {
  let currentYear = currentDate.substring(0, 4) * 1;
  let currentMonth = currentDate.substring(5, 7) * 1;
  let currentFiscalYear = "fiscalYear";
  // console.log(currentMonth, currentYear);
  if (currentMonth < 4)
    currentFiscalYear = `0${(currentYear - 1)
      .toString()
      .slice(-2)}-${currentYear.toString().slice(-2)}`;
  else
    currentFiscalYear = `0${currentYear.toString().slice(-2)}-${(
      currentYear + 1
    )
      .toString()
      .slice(-2)}`;
  return currentFiscalYear;
};
export const hasPermissions = (topicName) => {
  try {
    return (
      userInfo.authority.includes("all") ||
      userInfo.authority.includes(topicName)
    );
  } catch (error) {
    return false;
  }
};
export const dateWithSlash = (dateString) => {
  return dateString.replace(/-/g, "/");
};
export const numbersWithCommas = (number) => {
  if (number === undefined || number === null) {
    return "";
  }

  // Convert the number to a string
  let numberString = number.toString();

  // Split the string into the integer part and the fractional part (if any)
  let [integerPart, fractionalPart] = numberString.split(".");

  // Reverse the string to easily access digits from the end
  let reversed = integerPart.split("").reverse().join("");

  // Add commas according to the Nepali system
  let withCommas = "";
  for (let i = 0; i < reversed.length; i++) {
    if (i === 3 || (i > 3 && (i - 3) % 2 === 0)) {
      withCommas += ",";
    }
    withCommas += reversed[i];
  }

  // Reverse the string back to the original order
  withCommas = withCommas.split("").reverse().join("");

  // Append the fractional part (if any)
  if (fractionalPart !== undefined) {
    withCommas += "।" + fractionalPart;
  }

  return withCommas;
};
