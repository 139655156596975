import React from "react";
import { userDetails } from "../LoginDetails";

export default function OfficeFooter(props) {
  var isEnglish = props.isEnglish;
  var phoneNumber = isEnglish ? (
    <span>Phone:- {userDetails().office.फोन}</span>
  ) : (
    <span>फोन:- {userDetails().office.फोन}</span>
  );
  var email = isEnglish ? (
    <span>Email:- {userDetails().office.email.toLowerCase()}</span>
  ) : (
    <span>इमेल:- {userDetails().office.email.toLowerCase()}</span>
  );
  return (
    <div className="officeFooter">
      <hr />
      <div className="row">
        <div className={isEnglish ? "col englishFont" : "col"}>
          {phoneNumber}
        </div>
        <div className="col englishFont">{email}</div>
      </div>
      <br />
    </div>
  );
}
