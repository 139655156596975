import React, { useState } from "react";
import { userDetail } from "../Variables/TextVariables";
import { doc, setDoc } from "firebase/firestore";
import { database } from "../ServerWorks/firebase";

function EditProfile(props) {
  // Get the initial user details
  const initialDetails = userDetail();
  const [editedDetails, setEditedDetails] = useState(initialDetails);

  // Handle changes to the form inputs
  const handleChange = (event, key, nestedKey) => {
    if (nestedKey) {
      setEditedDetails((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          [nestedKey]: event.target.value,
        },
      }));
    } else {
      setEditedDetails((prev) => ({
        ...prev,
        [key]: event.target.value,
      }));
    }
  };

  const saveChanges = () => {
    setDoc(
      doc(database, "users", initialDetails.id), // Use the user's ID for the document reference
      editedDetails,
      { merge: true } // Merge the updates with existing data in Firestore
    )
      .then(() => {
        console.log("Details successfully updated:", editedDetails);
        alert("Profile updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        alert("Failed to update profile. Please try again.");
      });
  };

  return (
    <div
      style={{
        maxWidth: "600px",
        margin: "20px auto",
        padding: "20px",
        border: "1px solid #ddd",
        borderRadius: "10px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h2
        style={{
          textAlign: "center",
          marginBottom: "20px",
          color: "#333",
        }}
      >
        Edit Profile
      </h2>
      <form>
        {/* Username */}
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px" }}>
            Username:
          </label>
          <input
            type="text"
            value={editedDetails.userName}
            onChange={(e) => handleChange(e, "userName")}
            style={{
              width: "100%",
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
        </div>

        {/* Office Details */}
        <fieldset
          style={{
            marginBottom: "15px",
            border: "1px solid #ddd",
            borderRadius: "5px",
            padding: "10px",
          }}
        >
          <legend style={{ fontWeight: "bold", color: "#555" }}>
            Office Details
          </legend>
          {[
            "प्रकार",
            "ठेगाना",
            "engAddress",
            "नाम",
            "engName",
            "फोन",
            "email",
          ].map((key) => (
            <div style={{ marginBottom: "10px" }} key={key}>
              <label style={{ display: "block", marginBottom: "5px" }}>
                {key}:
              </label>
              <input
                type={key === "email" ? "email" : "text"}
                value={editedDetails.office[key]}
                onChange={(e) => handleChange(e, "office", key)}
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              />
            </div>
          ))}
        </fieldset>

        {/* Province and District */}
        {["province", "district"].map((key) => (
          <div style={{ marginBottom: "15px" }} key={key}>
            <label style={{ display: "block", marginBottom: "5px" }}>
              {key.charAt(0).toUpperCase() + key.slice(1)}:
            </label>
            <input
              type="text"
              value={editedDetails[key]}
              onChange={(e) => handleChange(e, key)}
              style={{
                width: "100%",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          </div>
        ))}

        {/* Municipality Details */}
        <fieldset
          style={{
            marginBottom: "15px",
            border: "1px solid #ddd",
            borderRadius: "5px",
            padding: "10px",
          }}
        >
          <legend style={{ fontWeight: "bold", color: "#555" }}>
            Municipality Details
          </legend>
          {["नाम", "प्रकार", "engName", "engType"].map((key) => (
            <div style={{ marginBottom: "10px" }} key={key}>
              <label style={{ display: "block", marginBottom: "5px" }}>
                {key}:
              </label>
              <input
                type="text"
                value={editedDetails.municipality[key]}
                onChange={(e) => handleChange(e, "municipality", key)}
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              />
            </div>
          ))}
        </fieldset>

        {/* वडा नं */}
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px" }}>
            वडा नं:
          </label>
          <input
            type="text"
            value={editedDetails["वडा नं"]}
            onChange={(e) => handleChange(e, "वडा नं")}
            style={{
              width: "100%",
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
        </div>

        {/* Save Button */}
        <button
          type="button"
          onClick={() => {
            console.log("Details updated:", editedDetails);
            saveChanges();
          }}
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "#007BFF",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
          }}
        >
          Save Changes
        </button>
      </form>
    </div>
  );
}

export default EditProfile;
