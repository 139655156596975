import React, { useState, useEffect, useRef } from "react";
import InputBox from "../InputBox";
import {
  currentFiscalYear,
  isValidDate,
  userDetail,
} from "../Variables/TextVariables";
import TableEntry from "../ParagraphItems/TableEntry";
import { participantsDetails, spaces } from "../Variables";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { database } from "../ServerWorks/firebase";
import { FormValidation } from "../FormValidation";

const Baithak = (props) => {
  const baithakDatas = props.data.data;
  const topic = props.title;
  const FiscalYear = currentFiscalYear();
  const databaseChainName =
    userDetail().district +
    "/" +
    userDetail().municipality.नाम +
    "/" +
    userDetail().office.प्रकार +
    userDetail().office["वडा नं"];
  const componentRef = useRef();
  const date = props.date;
  const specificItems = props.specificItems.items;
  var topicObjects = specificItems[topic];
  if (typeof topicObjects === "undefined") topicObjects = {};
  var topicsToDiscuss = topicObjects.topicsToDiscuss;
  if (typeof topicsToDiscuss === "undefined") topicsToDiscuss = [];
  var decisionsMade = topicObjects.decisionsMade;
  if (typeof decisionsMade === "undefined") decisionsMade = [];
  console.log(topicsToDiscuss);
  console.log("Mukesh");

  const meetingDetails = [
    "शिर्षक",
    "मिति",
    "अध्यक्षता गर्ने व्यक्तिको नाम",
    "अध्यक्षता गर्ने व्यक्तिको पद",
    "समय",
  ];
  const [allowInvitedParticipants, setAllowInvitedParticipants] =
    useState(false);
  const [table, setTable] = useState([]);
  const [table_participants, setTable_participants] = useState([]);

  const baithakUpdater = (field, value) => {
    const updatedValue = {};
    updatedValue[field] = value;
    props.specificItems.updater(topic, {
      ...specificItems[topic],
      ...updatedValue,
    });
  };
  const setDefaultParticipants = () => {
    console.log("set defaults called");
    // make sure multiple partcipants can also be saved providing name as label and participants list.
    console.log(topicObjects.participants);
    const documentRef = doc(
      database,
      databaseChainName,
      "baithakDefaultParticipants"
    );
    setDoc(documentRef, { participants: topicObjects.participants })
      .then(() => {
        console.log("defaults are set ");
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const loadDefaultParticipants = () => {
    const documentRef = doc(
      database,
      databaseChainName,
      "baithakDefaultParticipants"
    );
    getDoc(documentRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.data());
          let defaultParticipants = snapshot.data();

          props.specificItems.updater(topic, {
            ...specificItems[topic],
            ...defaultParticipants,
          });
        } else {
          console.log("no such snapshot");
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const participantsInput = (
    <div>
      <h4>Participants</h4>
      <button
        onClick={loadDefaultParticipants}
        id="loadParticipantsDefaultsButton"
      >
        Load Default Participants
      </button>

      <TableEntry
        borderless={true}
        style={{ height: "35px" }}
        setTable={setTable}
        inputHeads={participantsDetails}
        variableName="participants"
        details={topicObjects}
        detailsUpdater={baithakUpdater}
      />
      <button
        onClick={setDefaultParticipants}
        id="setParticipantsDefaultsButton"
      >
        Set Default Participants
      </button>
      <button
        onClick={() => setAllowInvitedParticipants(!allowInvitedParticipants)}
      >
        {" "}
        {allowInvitedParticipants
          ? "Remove Invited Participants"
          : "Add Invited Participants"}
      </button>
    </div>
  );
  const invitedParticipants = (
    <div>
      <h3>Invited Participants</h3>
      <TableEntry
        borderless={true}
        headless={true}
        setTable={setTable_participants}
        inputHeads={participantsDetails}
        variableName="invited_participants"
        details={topicObjects}
        detailsUpdater={baithakUpdater}
      />
    </div>
  );

  const handleAddTopic = () => {
    baithakUpdater("topicsToDiscuss", [...topicsToDiscuss, ""]);
  };
  const handleAddDecision = (topicIndex) => {
    baithakUpdater("decisionsMade", [...decisionsMade, ""]);
  };

  const inputs = meetingDetails.map((item, index) => {
    return (
      <div className="col" key={index}>
        <InputBox
          field={item}
          value={topicObjects[item]}
          required={true}
          handleChanges={(field, value) => {
            baithakUpdater(
              field,
              field.endsWith("मिति") ? isValidDate(value) : value
            );
          }}
        />
      </div>
    );
  });

  const handleTextAreaInput = (e) => {
    e.target.style.height = "auto"; // Reset the height
    e.target.style.height = `${e.target.scrollHeight}px`; // Set to scroll height
  };
  const printAction = (
    <div className="printAction">
      <FormValidation ref={componentRef} buttonName={`Print बैठक`} />
    </div>
  );
  return (
    <div className="mukesh">
      <div className="row">{inputs}</div>
      {participantsInput}
      {allowInvitedParticipants && invitedParticipants}
      <form>
        <ul></ul>

        <h3>Topics to Discuss</h3>
        <button type="button" onClick={handleAddTopic}>
          Add Topic
        </button>
        {topicsToDiscuss.map((topic, index) => (
          <div key={index}>
            <label>
              Topic Name:
              <textarea
                type="text"
                style={{ width: "600px", height: "auto" }}
                value={topic}
                onChange={(e) => {
                  const newTopics = [...topicsToDiscuss];
                  newTopics[index] = e.target.value;
                  baithakUpdater("topicsToDiscuss", newTopics);
                }}
              />
            </label>

            <button
              type="button"
              onClick={() =>
                //remove the corresonding topic from topicsToDiscuss
                baithakUpdater(
                  "topicsToDiscuss",
                  topicsToDiscuss.filter((_, i) => i !== index)
                )
              }
            >
              Remove Topic
            </button>
          </div>
        ))}
        <button type="button" onClick={() => handleAddDecision()}>
          Add Decisions
        </button>
        {decisionsMade.map((decision, index) => (
          <div key={index}>
            <label>
              Decision Made:
              <textarea
                style={{ width: "600px", height: "auto" }}
                value={decision}
                onChange={(e) => {
                  const newDecision = [...decisionsMade];
                  newDecision[index] = e.target.value;
                  baithakUpdater("decisionsMade", newDecision);
                }}
                onInput={handleTextAreaInput}
              />
            </label>

            <button
              type="button"
              onClick={() =>
                //remove the corresonding topic from topicsToDiscuss
                baithakUpdater(
                  "decisionsMade",
                  decisionsMade.filter((_, i) => i !== index)
                )
              }
            >
              Remove Decision
            </button>
          </div>
        ))}
      </form>
      {printAction}
      <div
        ref={componentRef}
        contentEditable={true}
        style={{ fontFamily: "Kalimati", fontWeight: "regular" }}
      >
        {" "}
        <div className="row">
          <div className="col">
            <strong> बैठक संख्याः </strong>
          </div>
          <div className="col"></div> मिति:- {topicObjects[meetingDetails[1]]}{" "}
          <br />
          समयः- {topicObjects[meetingDetails[4]]}
        </div>
        <hr />
        <strong>१. बैठकको नामः {topicObjects[meetingDetails[0]]}</strong> <br />
        <strong>२. बैठकको अध्यक्षता गर्ने व्यक्तिः</strong> श्री{" "}
        {topicObjects[meetingDetails[2]]} <br />
        <strong>३. बैठकको अध्यक्षता गर्ने व्यक्तिको पदः</strong>{" "}
        {topicObjects[meetingDetails[3]]} <br />
        <strong>
          ४. <u>बैठकको उपस्थितिः</u>
        </strong>
        {table}{" "}
        {allowInvitedParticipants && (
          <span>
            <br />
            ४.१
            <strong>
              {" "}
              <u>आमन्त्रितः</u>{" "}
            </strong>
            {table_participants}
          </span>
        )}
        ५.{" "}
        <strong>
          {" "}
          <u>प्रस्ताव तथा छलफलका विषयहरु</u>{" "}
        </strong>
        <ol>
          {topicsToDiscuss.map((topic, index) => (
            <li key={index}>{topic}</li>
          ))}
        </ol>
        <strong>
          {" "}
          ६.<u>निर्णयहरु</u>
        </strong>
        {decisionsMade.map((decision, index) => (
          <div>
            {" "}
            {spaces(2)}
            <u>
              <strong>निर्णय नं {index + 1}</strong>
            </u>{" "}
            <br /> {spaces(4)}
            {decision}
          </div>
        ))}
        <hr />
      </div>
    </div>
  );
};

export default Baithak;
