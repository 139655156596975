import React, { useState, useRef } from "react";
import "./DynamicTable.css"; // Importing the CSS file

const DynamicTable = () => {
  const [columns, setColumns] = useState(["S.N.", "Column 1"]);
  const [rows, setRows] = useState([{ "S.N.": "1", "Column 1": "" }]);
  const columnRefs = useRef([]);
  const rowRefs = useRef([]);

  // Function to update a column header name
  const updateColumnName = (colIndex, newName) => {
    const updatedColumns = [...columns];
    const oldName = updatedColumns[colIndex];
    updatedColumns[colIndex] = newName;

    const updatedRows = rows.map((row) => {
      const { [oldName]: value, ...rest } = row;
      return { ...rest, [newName]: value };
    });

    setColumns(updatedColumns);
    setRows(updatedRows);
  };

  // Function to add a new column
  const addColumn = () => {
    const newColumnName = `Column ${columns.length + 1}`;
    setColumns([...columns, newColumnName]);
    setRows(rows.map((row) => ({ ...row, [newColumnName]: "" })));
  };

  // Function to delete a specific column
  const deleteColumn = (colIndex) => {
    if (columns.length > 1) {
      const columnToDelete = columns[colIndex];
      const updatedColumns = columns.filter((_, index) => index !== colIndex);
      const updatedRows = rows.map((row) => {
        const { [columnToDelete]: _, ...rest } = row;
        return rest;
      });

      setColumns(updatedColumns);
      setRows(updatedRows);
    }
  };

  // Function to add a new row
  const addRow = () => {
    const newRow = columns.reduce((acc, col) => ({ ...acc, [col]: "" }), {});
    setRows([...rows, newRow]);
  };

  // Function to delete a specific row
  const deleteRow = (rowIndex) => {
    if (rows.length > 1) {
      setRows(rows.filter((_, index) => index !== rowIndex));
    }
  };

  // Handle cell value changes
  const handleInputChange = (rowIndex, columnKey, value) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][columnKey] = value;
    setRows(updatedRows);
  };

  // Column resizing logic for non-editable table
  const handleDragStart = (e, colIndex) => {
    e.preventDefault();
    const startX = e.pageX;
    const startWidth = columnRefs.current[colIndex].offsetWidth;

    const handleDrag = (e) => {
      const newWidth = startWidth + e.pageX - startX;
      columnRefs.current[colIndex].style.width = `${newWidth}px`;
    };

    const handleDragEnd = () => {
      document.removeEventListener("mousemove", handleDrag);
      document.removeEventListener("mouseup", handleDragEnd);
    };

    document.addEventListener("mousemove", handleDrag);
    document.addEventListener("mouseup", handleDragEnd);
  };

  // Row resizing logic
  const handleRowDragStart = (e, rowIndex) => {
    e.preventDefault();
    const startY = e.pageY;
    const startHeight = rowRefs.current[rowIndex].offsetHeight;

    const handleRowDrag = (e) => {
      const newHeight = startHeight + e.pageY - startY;
      rowRefs.current[rowIndex].style.height = `${newHeight}px`;
    };

    const handleRowDragEnd = () => {
      document.removeEventListener("mousemove", handleRowDrag);
      document.removeEventListener("mouseup", handleRowDragEnd);
    };

    document.addEventListener("mousemove", handleRowDrag);
    document.addEventListener("mouseup", handleRowDragEnd);
  };

  // Print table function
  const printTable = () => {
    const printWindow = window.open("", "", "width=800,height=600");
    printWindow.document.write(
      "<html><head><title>Printable Table</title></head><body>"
    );
    printWindow.document.write(
      document.getElementById("printable-table").outerHTML
    );
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className="dynamic-table-container">
      <h1>Dynamic Table</h1>

      {/* Editable Dynamic Table */}
      <h2>Editable Table</h2>
      <table className="editable-table">
        <thead>
          <tr>
            {columns.map((col, colIndex) => (
              <th key={colIndex}>
                <input
                  type="text"
                  value={col}
                  onChange={(e) => updateColumnName(colIndex, e.target.value)}
                  className="column-input"
                />
                <button
                  onClick={() => deleteColumn(colIndex)}
                  className="delete-button"
                >
                  -
                </button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex} ref={(el) => (rowRefs.current[rowIndex] = el)}>
              {columns.map((col, colIndex) => (
                <td key={colIndex}>
                  <input
                    type="text"
                    value={row[col] || ""}
                    onChange={(e) =>
                      handleInputChange(rowIndex, col, e.target.value)
                    }
                    className="row-input"
                  />
                </td>
              ))}
              <td>
                <button
                  onClick={() => deleteRow(rowIndex)}
                  className="delete-button"
                >
                  -
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={addRow} className="add-row-button">
        + Row
      </button>

      <button onClick={addColumn} className="add-column-button">
        + Column
      </button>

      {/* Non-editable Printable Table */}
      <h2>Printable Table (Resizable Columns & Rows)</h2>
      <div
        id="printable-table"
        className="printable-table"
        contentEditable="true"
      >
        <table>
          <thead>
            <tr>
              {columns.map((col, colIndex) => (
                <th
                  key={colIndex}
                  ref={(el) => (columnRefs.current[colIndex] = el)}
                  className="resizable-column"
                >
                  {col}
                  <span
                    onMouseDown={(e) => handleDragStart(e, colIndex)}
                    className="resize-handle"
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                ref={(el) => (rowRefs.current[rowIndex] = el)}
                onMouseDown={(e) => handleRowDragStart(e, rowIndex)}
              >
                {columns.map((col, colIndex) => (
                  <td key={colIndex}>{row[col]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Print Button */}
      <div className="print-button-container">
        <button onClick={printTable} className="print-button">
          Print Table
        </button>
      </div>
    </div>
  );
};

export default DynamicTable;
