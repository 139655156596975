import React from 'react'
import { spaces } from '../Variables';

export default function Logbook(props) {
    const details = props.details;

    return (
        <div >

            <div className="row">
                <div className="col-3">
                    <div>
                        सवारी नं : {details["multiple"]?"":details["सवारी नं"]}
                    </div>
                    <div>
                        सवारी साधन / मेशिनरीको नाम : {details["multiple"]?"": details["सवारी साधन वा मेशिनरीको नाम"]}
                    </div>
                    <div>कार्यालय कोड नं : ८०१०११४०३</div>

                </div>
                <div className="col-6">
                    <h4 style={{ textAlign: 'center', marginBottom: '20px' }}>
                        इनरुवा नगरपालिका, नगर कार्यपालिकाको कार्यालय<br />
                        इनरुवा, सुनसरी<br />
                        सवारी साधन र मेशिन प्रयोगको लगबुक
                    </h4>
                </div>
                <div className='col-3'>
                    म. ले. प. फा. नं. ९०५
                    <br />
                    साविकको फाराम नं. १
                </div>
            </div>
            <div className="row">
                <div class="col-md-4 offset-md-7"> सवारी चालक/अपरेटरको नाम : {details["multiple"]?"":details["सवारी प्रयोग वा संचालन गर्ने कर्मचारी वा पदाधिकारी"]}</div>
            </div>
            <table className='table-logbook'>
                <thead>
                    <tr>
                        <th rowSpan={"2"}>मिति</th>
                        <th colSpan="2">ठाउँ</th>
                        <th colSpan="2">किलो मिटर</th>
                        <th rowSpan="2">जम्मा</th>
                        <th rowSpan="2">पेट्रोलियम पदार्थ <br /> पेट्रोल, डिजेल, अन्य</th>
                        <th rowSpan="2">सवारी साधन वा मेशिन <br /> प्रयोगको उद्देश्य</th>
                        <th colSpan="2">सवारी प्रयोग वा संचालन गर्ने <br /> कर्मचारी/पदाधिकारी</th>
                        <th rowSpan="2">कैफियत</th>
                    </tr>
                    <tr>

                        <th>बाट</th>
                        <th>सम्म</th>
                        <th>बाट</th>
                        <th>सम्म</th>

                        <th>नाम</th>
                        <th> {spaces(3)}दस्तखत{spaces(3)}</th>

                    </tr>
                </thead>
                <tbody>
                {details["multiple"] ?.map((item, index) => (
        <tr key={index} style={{ height: `${300 / (details["multiple"].length + 1)}px`,borderTop: 'none', borderBottom: 'none'  }}>
            <td style={{ wordWrap: 'break-word', whiteSpace: 'normal',borderTop: 'none', borderBottom: 'none'  }}>{item["मिति वा अवधि"] || ''}</td>
            <td   style={{ borderTop: 'none', borderBottom: 'none' }}>{item["ठाँउ बाट"] || ''}</td>
            <td  style={{ borderTop: 'none', borderBottom: 'none' }}>{item["ठाँउ सम्म"] || ''}</td>
            <td  style={{ borderTop: 'none', borderBottom: 'none' }}>{item["किलो मिटर बाट"] || ''}</td>
            <td  style={{ borderTop: 'none', borderBottom: 'none' }}>{item["किलो मिटर सम्म"] || ''}</td>
            <td  style={{ borderTop: 'none', borderBottom: 'none' }}>{item["जम्मा"] || ''}</td>
            <td  style={{ borderTop: 'none', borderBottom: 'none' }}>{item["पेट्रोलियम पदार्थ: पेट्रोल, डिजेल, अन्य"] || ''}</td>
            <td  style={{ borderTop: 'none', borderBottom: 'none' }}>{item["सवारी साधन वा मेशिन प्रयोगको उद्देश्य"] || ''}</td>
            <td  style={{ borderTop: 'none', borderBottom: 'none' }}>{item["सवारी प्रयोग वा संचालन गर्ने कर्मचारी वा पदाधिकारी"] || ''}</td>
            <td style={{ borderTop: 'none', borderBottom: 'none' }}></td>
            <td  style={{ borderTop: 'none', borderBottom: 'none' }}>{`${item["कैफियत"] || '' } ${item["सवारी साधन वा मेशिनरीको नाम"]} - ${item["सवारी नं"]}`}</td>
        </tr>
    ))}

                    <tr style={{ height: details["multiple"] ? `${300 / (details["multiple"].length + 1)}px` : '300px', borderTop: 'none'  }}>
                        <td style={{ wordWrap: 'break-word', whiteSpace: 'normal', borderTop: 'none'  }}>{details["मिति वा अवधि"]}</td>
                        <td  style={{ borderTop: 'none' }} >{details["ठाँउ बाट"]}</td>
                        <td style= {{ borderTop: 'none' }} >{details["ठाँउ सम्म"]}</td>
                        <td style= {{ borderTop: 'none' }}>{details["किलो मिटर बाट"]}</td>
                        <td style= {{ borderTop: 'none' }}>{details["किलो मिटर सम्म"]}</td>
                        <td style= {{ borderTop: 'none' }}>{details["जम्मा"]}</td>
                        <td style= {{ borderTop: 'none' }}>{details["पेट्रोलियम पदार्थ: पेट्रोल, डिजेल, अन्य"]}</td>
                        <td style= {{ borderTop: 'none' }}>{details["सवारी साधन वा मेशिन प्रयोगको उद्देश्य"]}</td>
                        <td style= {{ borderTop: 'none' }}>{details["सवारी प्रयोग वा संचालन गर्ने कर्मचारी वा पदाधिकारी"]}</td>
                        <td style={{ borderTop: 'none'}}></td>
                        <td style= {{ borderTop: 'none' }}>{`${details["कैफियत"] || '' }${ details["multiple"]? `${details["सवारी साधन वा मेशिनरीको नाम"]} - ${details["सवारी नं"]}`:""}`}</td>

                    </tr>
                </tbody>
            </table>
            <div className="row">
                <div className="col-6">
                    <div>सवारी चालक / अपरेटरको नाम : {details["multiple"]?"":details["सवारी प्रयोग वा संचालन गर्ने कर्मचारी वा पदाधिकारी"]}</div>
                    <br />
                    <div>दस्तखत :</div>

                </div>
                <div className="col-2">
                    <div>
                        <br />
                    </div>
                    <br />
                    <br />
                    मिति : </div>
                <div className="col-4" style={{ textAlign: 'right' }}>
                    <br />
                    <br />

                    ......................
                    <br />
                    प्रमाणित गर्ने
                </div>
            </div>

        </div>

    )
}
