import React, { useRef, useState } from "react";
import InputBox from "./InputBox";
import SelectBox from "./SelectBox";
import { database } from "./ServerWorks/firebase";
import Business from "./SifarisItems/Business";
import LetterPad from "./SifarisItems/LetterPad";
import Nagarikta from "./SifarisItems/Nagarikta";
import NataPramanit from "./SifarisItems/NataPramanit";
import TwoNames from "./SifarisItems/TwoNames";
import Person from "./Variables/Person";
import { collection, addDoc } from "firebase/firestore";
import { FormValidation } from "./FormValidation";
import {
  getApplicant,
  isValidDate,
  officeAddress,
  userDetail,
} from "./Variables/TextVariables";
import { toast, ToastContainer } from "react-toastify";
import RentTaxAccount from "./SifarisItems/RentTaxAccount";
import OpenClosedHouse from "./SifarisItems/OpenClosedHouse";
import MohiLagatKatta from "./SifarisItems/MohiLagatKatta";
import BirthDate from "./SifarisItems/BirthDate";
import Marriage from "./SifarisItems/Marriage";
import Applicant from "./ParagraphItems/Applicant";
import DateItem from "./ParagraphItems/DateItem";
import Health from "./SifarisItems/Health";
import HouseDown from "./SifarisItems/HouseDown";
import PersonalDetails from "./SifarisItems/PersonalDetails";
import HouseinMap from "./SifarisItems/HouseinMap";
import Dhanipurja from "./SifarisItems/Dhanipurja";
import KittaKaat from "./SifarisItems/KittaKaat";
import Protector from "./SifarisItems/Protector";
import Alive from "./SifarisItems/Alive";
import Hakwala from "./SifarisItems/Hakwala";
import NaamSaari from "./SifarisItems/NaamSaari";
import UdhyogThauSaari from "./SifarisItems/UdhyogThauSaari";
import BasicShcool from "./SifarisItems/BasicShcool";
import LandEvaluation from "./SifarisItems/LandEvaluation";
import GharBato from "./SifarisItems/GharBato";
import Helpless from "./SifarisItems/Helpless";
import EconomicStatus from "./SifarisItems/EconomicStatus";
import SchoolThauSaari from "./SifarisItems/SchoolThauSaari";
import Dhara from "./SifarisItems/Dhara";
import Jatiya from "./SifarisItems/Jatiya";
import Institute from "./SifarisItems/Institute";
const variables = require("./Variables");
const sifarisPersonDetails = variables.sifarisPersonDetails;
var sifarisItemsCount = 0;
const Date = require("./Date");
var displayContent = "";
export const Sifaris = (props) => {
  const databaseChainName =
    userDetail().district +
    "/" +
    userDetail().municipality.नाम +
    "/Ward" +
    userDetail().office["वडा नं"];

  console.log("hi hi hi", props.applicant.Name);
  const [inputs, setInputs] = useState([]);
  const sender = props.person.sender;
  const receiver = props.person.receiver;
  const specificItems = props.specificItems.items;
  const topic = specificItems.topic;

  const [todaysDate] = useState(Date.getNepaliDate);
  const [spouseSelected, setSpouseSelected] = useState(false);
  // const [sameAsApplicant, setSameAsApplicant] = useState(true);
  const date = {
    currentDate: props.date.currentDate,
    applicationDate: props.date.applicationDate,
  };

  var topicObjects = specificItems[topic];

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("topic:", topic, sifarisItemsCount);
    sifarisItemsCount++;
    // database.collection("Sifaris").add({
    //     topic: topicObjects

    // });
    addDoc(
      collection(
        database,
        databaseChainName,
        "079-80",
        "Sifaris",
        topic,
        todaysDate
      ),
      {
        details: topicObjects,
        date: props.date,
        person: props.person,
        applicant: props.applicant,
      }
    )
      // setDoc(doc(database, "Sunsari", "Dharan", "Ward 2", "Sifaris", topic, (sifarisItemsCount).toString()), {
      //     details: topicObjects,
      //     "date": date.currentDate,
      //     "applicationDate": date.applicationDate
      // }, { merge: true })
      .then(() => {
        toast("Data submitted 👍");
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const senderHeading = <h3>Sender</h3>;
  const receiverHeading = <h3>Receiver</h3>;
  const selectTopic = (
    <SelectBox
      items={"विषय छान्नुहोस"}
      value={topic}
      options={variables.SifarisItems}
      handleChanges={(value) => handleTopic(value)}
    />
  );

  const handleSender = (field, value) => {
    const updatedValue = {};
    updatedValue[field] = value;
    props.handlePerson("sender", { ...sender, ...updatedValue });
  };
  const senderItems = sifarisPersonDetails.map((items, index) => {
    return (
      <div className="row" key={index}>
        <InputBox
          field={items}
          value={props.person.sender[items]}
          handleChanges={handleSender}
        />
      </div>
    );
  });
  const handleReceiver = (field, value) => {
    const updatedValue = {};
    updatedValue[field] = value;
    props.handlePerson("receiver", { ...receiver, ...updatedValue });
  };
  const receiverItems = sifarisPersonDetails.map((items, index) => {
    return (
      <div className="row" key={index}>
        <InputBox
          inputHead="Receiver"
          field={items}
          value={props.person.receiver[items]}
          handleChanges={handleReceiver}
        />
      </div>
    );
  });
  const handleTopic = (value) => {
    props.specificItems.updater("topic", value);
    console.log({ topic });

    variables.SifarisItems.map((item) => {
      if (value === item) {
        let sender = {};
        sender.Post = "वडा अध्यक्ष";
        sender.Office = userDetail().office["नाम"];
        sender.Address =
          userDetail().municipality["नाम"] +
          "-" +
          userDetail().office["वडा नं"] +
          ", " +
          userDetail().district;
        let receiver = { Post: "", Office: "", Address: "" };

        switch (value) {
          case "नागरिकता":
            receiver.Post = "प्रमुख जिल्ला अधिकारी";
            receiver.Office = "इलाका/जिल्ला प्रशासन कार्यालय";
            receiver.Address =
              userDetail().municipality["नाम"] + ", " + userDetail().district;
            break;
          // case "व्यवसाय": receiver.Office = 'आन्तरिक राजश्व कार्यालय'; receiver.Address = userDetail().municipality["नाम"] + ", " + userDetail().district; break;
          case "घर पाताल वा भत्के, भत्काएको प्रमाणित":
          case "मोही लगत कट्टाको सिफारिस":
            receiver.Office = "भूमिसुधार/मालपोत/नापी कार्यालय";
            receiver.Address =
              userDetail().municipality["नाम"] + ", " + userDetail().district;
            break;
          case "स्वास्थ्य":
            receiver.Office = "जिल्ला अस्पताल";
            receiver.Address =
              userDetail().municipality["नाम"] + ", " + userDetail().district;
            break;
          case "कित्ताकाट गर्न सिफारिस":
          case "नक्सामा घर कायम गर्ने सिफारिस":
            receiver.Office = "नापी कार्यालय";
            receiver.Address =
              userDetail().municipality["नाम"] + ", " + userDetail().district;
            break;
          case "नामसारी सिफारिस वा जग्गाको हक सिफारिस":
          case "घरबाटो / चारकिल्ला प्रमाणित / पूर्जामा घर कायम गर्ने सिफारिस ":
          case "जग्गा धनी प्रमाणपूर्जा हराएको सिफारिस":
            receiver.Office = "मालपोत कार्यालय";
            receiver.Address =
              userDetail().municipality["नाम"] + ", " + userDetail().district;
            break;
          case "आधारभूत विद्यालय खोल्न सिफारिस / विद्यालय कक्षा थप गर्ने":
            receiver.Office =
              userDetail().municipality["प्रकार"] + " कार्यपालिकाको कार्यालय";
            receiver.Address =
              userDetail().municipality["नाम"] + ", " + userDetail().district;
            break;
          case "संस्था":
            receiver.Office = "जिल्ला प्रशासन कार्यालय";
            receiver.Address =
              userDetail().municipality["नाम"] + ", " + userDetail().district;
            break;

          default:
            break;
        }
        props.handlePerson("", {
          ...props.person,
          ...{ sender, receiver },
        });
      }
      return "";
    });
  };
  const handleNagariktaSpecificItems = (field, value, innerfield) => {
    const updatedValue = {};
    updatedValue[field] = value;

    if (field === "gender") {
      document.getElementById("Spouse_label").innerHTML =
        value === "महिला" ? "पति" : value === "पुरुष" ? "पत्‍नी" : "Spouse";
    }
    if (field === variables.nagariktaSpecificItems.otherItems[3]) {
      updatedValue[field] = isValidDate(value);
    }
    if (topic === "नागरिकता")
      if (variables.nagariktaSpecificItems.otherPeoples.includes(field)) {
        // let topicItems = specificItems[topic]; // nagarikta, byawasaya band etc

        let updatingField = {};
        updatingField[innerfield] = value;
        let otherItemsObject = { ...topicObjects[field], ...updatingField }; // बाबु, आमा, पत्‍नी etc
        updatedValue[field] = otherItemsObject;
      }

    // updatedValue[field] = value;value
    console.log("topic", topic);
    console.log("field:", field, "value:", value);
    props.specificItems.updater(topic, {
      ...specificItems[topic],
      ...updatedValue,
    });
  };
  // const tempForTwo = ['One']; // used to return two items address and citizenship

  const topicSpecificItems =
    topic === "नागरिकता" ? (
      Object.keys(variables.nagariktaSpecificItems).map((keys, index) => {
        return (
          <div className="row" key={index}>
            {variables.nagariktaSpecificItems[keys].map((items, index) => {
              var value = specificItems[topic][items];
              var finalValue = "Mukesh";

              if (items === "gender" || items === "type") {
                const selectedBox =
                  items === "gender" ? (
                    <SelectBox
                      items={items}
                      value={value}
                      options={variables.genderItems}
                      handleChanges={(value) =>
                        handleNagariktaSpecificItems(items, value)
                      }
                    />
                  ) : (
                    <SelectBox
                      items={items}
                      value={value}
                      options={variables.nagariktaTypes}
                      handleChanges={(value) =>
                        handleNagariktaSpecificItems(items, value)
                      }
                    />
                  );
                return (
                  <div className="col" key={index}>
                    {selectedBox}
                  </div>
                );
              } else {
                return (
                  <div className="col" key={index}>
                    {variables.nagariktaSpecificItems.otherPeoples.includes(
                      items
                    ) ? (
                      <fieldset>
                        <b id={items + "_label"}>{items}</b>
                        {variables.spaces(5)}
                        {items === "Spouse" ? (
                          <input
                            type="checkbox"
                            id={items + "_checkbox"}
                            checked={spouseSelected}
                            onChange={(e) => {
                              topicObjects["Spouse"]["isRequired"] =
                                e.target.checked;
                              setSpouseSelected(e.target.checked);
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <div className="row justify-content-start">
                          {variables.individualDetails.map((xx, index) => {
                            if (items === "Spouse" && !spouseSelected) {
                              return "";
                            }

                            xx === variables.individualDetails[0]
                              ? (finalValue = value[xx])
                              : value[xx] === ""
                              ? [1].map(() => {
                                  if (xx === "ठेगाना")
                                    finalValue =
                                      topicObjects.पालिका +
                                      "-" +
                                      topicObjects.वडा +
                                      ", " +
                                      topicObjects.जिल्ला;
                                  else finalValue = "नेपाली";
                                  return "";
                                })
                              : (finalValue = value[xx]);

                            // topicObjects = { ...topicObjects, ...topicObjects[items], ...{ [xx]: finalValue } }

                            return (
                              <div className="row" key={index}>
                                <label htmlFor="">{xx}</label>
                                <input
                                  type="text"
                                  value={finalValue}
                                  id={items + "_" + xx}
                                  placeholder={xx}
                                  field={items}
                                  onChange={(e) =>
                                    handleNagariktaSpecificItems(
                                      items,
                                      e.target.value,
                                      xx
                                    )
                                  }
                                />
                              </div>
                            );
                          })}
                        </div>
                      </fieldset>
                    ) : (
                      <InputBox
                        field={items}
                        value={value}
                        handleChanges={handleNagariktaSpecificItems}
                      />
                    )}
                  </div>
                );
              }
            })}
          </div>
        );
      })
    ) : topic === "व्यवसाय" ? (
      <div className="row">
        <div className="input-group">
          {variables.businessTransferSpecificItems.map((items, index) => {
            if (index >= 3) {
              if (topicObjects.businessWork === "नामसारी") {
                let isSameAsApplicant =
                  specificItems[topic][items]["sameAsApplicant"];

                console.log(
                  specificItems[topic][items]["sameAsApplicant"],
                  "same as applicant?"
                );
                if (isSameAsApplicant !== false) {
                  if (typeof isSameAsApplicant === "undefined")
                    isSameAsApplicant = index === 3 ? true : false;

                  if (isSameAsApplicant) {
                    specificItems[topic][items] = {
                      ...specificItems[topic][items],
                      ...props.applicant,
                    };
                  }
                }
                return (
                  <div key={index}>
                    <br />
                    <span className="p-3 mb-2 bg-danger text-white">
                      {items}
                    </span>{" "}
                    {variables.spaces(5)}
                    {console.log(specificItems[topic][items], { index })}
                    <input
                      type="checkbox"
                      id={items + "_checkbox"}
                      checked={isSameAsApplicant}
                      onChange={(e) => {
                        let otherindex = index === 3 ? 4 : 3;
                        let allowChange = true;
                        console.log(
                          "otherIndex",
                          otherindex,
                          " ",
                          specificItems[topic][
                            variables.businessTransferSpecificItems[otherindex]
                          ]["sameAsApplicant"]
                        );

                        if (
                          specificItems[topic][
                            variables.businessTransferSpecificItems[otherindex]
                          ]["sameAsApplicant"]
                        )
                          allowChange = false;

                        if (
                          typeof specificItems[topic][
                            variables.businessTransferSpecificItems[otherindex]
                          ]["sameAsApplicant"] === "undefined" &&
                          index === 4
                        )
                          allowChange = false;

                        if (allowChange)
                          handleNagariktaSpecificItems(items, {
                            ...specificItems[topic][items],
                            ...{ ["sameAsApplicant"]: e.target.checked },
                          });
                      }}
                    />{" "}
                    <b className="p-3 mb-2 bg-primary text-white">
                      {" "}
                      same as Applicant?
                    </b>
                    <Person
                      person={specificItems[topic][items]}
                      handlePerson={(field, value) => {
                        console.log("i am here");
                        handleNagariktaSpecificItems(items, {
                          ...specificItems[topic][items],
                          ...{ [field]: value },
                        });
                      }}
                    />
                  </div>
                );
              } else return "";
            }
            return (
              <div className="col" key={index}>
                <InputBox
                  field={items}
                  value={specificItems[topic][items]}
                  handleChanges={handleNagariktaSpecificItems}
                />
              </div>
            );
          })}
        </div>
      </div>
    ) : topic === "फरक फरक विवरण" ? (
      <div className="row">
        {variables.twoNamesSpecificItems.map((items, index) => {
          let value = specificItems[topic][items];
          if (!value && index < 2) value = variables.twoNamesDefaults[index];
          return (
            <div className="col" key={index}>
              <InputBox
                field={items}
                value={value}
                handleChanges={handleNagariktaSpecificItems}
              />
            </div>
          );
        })}
      </div>
    ) : (
      ""
    );
  const senderSide = (
    <>
      {senderHeading}
      {senderItems}
    </>
  );
  const receiverSide = (
    <>
      {receiverHeading}
      {receiverItems}
    </>
  );

  const componentRef = useRef();
  const printAction = (
    <div className="printAction">
      <FormValidation ref={componentRef} />
    </div>
  );
  const startingText = (
    <span>
      {variables.spaces(5)} उपरोक्त विषयमा यही मिति{" "}
      <b>{date.applicationDate}</b> गते यस {officeAddress()} को वडा कार्यालयमा{" "}
      <b>{getApplicant()}</b>ले{" "}
    </span>
  );
  const prop = {
    setInputs: setInputs,
    date: date,
    person: props.person,
    topic: topic,
    applicant: props.applicant,
    details: topicObjects,
    detailsUpdater: handleNagariktaSpecificItems,
    componentRef: componentRef,
    starter: startingText,
  };

  switch (topic) {
    case "नागरिकता":
      displayContent = <Nagarikta prop={prop} />;
      break;
    case "फरक फरक विवरण":
      displayContent = <TwoNames prop={prop} />;
      break;
    case "नाता प्रमाणित":
      displayContent = <NataPramanit prop={prop} />;
      break;
    case "व्यवसाय":
      displayContent = <Business prop={prop} />;
      break;
    case "Letter Pad":
      displayContent = <LetterPad prop={prop} />;
      break;
    case "करको लेखाजोखा":
      displayContent = <RentTaxAccount prop={prop} />;
      break;
    case "बन्द घर वा कोठा खोल्न रोहवरमा बस्‍ने/बसेको प्रमाणित":
      displayContent = <OpenClosedHouse prop={prop} />;
      break;
    case "मोही लगत कट्टाको सिफारिस":
      displayContent = <MohiLagatKatta prop={prop} />;
      break;
    case "जन्म मिति":
      displayContent = <BirthDate prop={prop} />;
      break;
    case "विवाह":
      displayContent = <Marriage prop={prop} />;
      break;
    case "स्वास्थ्य":
      displayContent = <Health prop={prop} />;
      break;
    case "घर पाताल वा भत्के, भत्काएको प्रमाणित":
      displayContent = <HouseDown prop={prop} />;
      break;
    case "व्यक्तिगत विवरण प्रमाणित वा सिफारिस":
      displayContent = <PersonalDetails prop={prop} />;
      break;
    case "नक्सामा घर कायम गर्ने सिफारिस":
      displayContent = <HouseinMap prop={prop} />;
      break;
    case "जग्गा धनी प्रमाणपूर्जा हराएको सिफारिस":
      displayContent = <Dhanipurja prop={prop} />;
      break;
    case "कित्ताकाट गर्न सिफारिस":
      displayContent = <KittaKaat prop={prop} />;
      break;
    case "संरक्षक प्रमाणित गर्ने":
      displayContent = <Protector prop={prop} />;
      break;
    case "जिवित रहेको सिफारिस":
      displayContent = <Alive prop={prop} />;
      break;
    case "हकवाला वा हकदार प्रमाणित":
      displayContent = <Hakwala prop={prop} />;
      break;
    case "नामसारी सिफारिस वा जग्गाको हक सिफारिस":
      displayContent = <NaamSaari prop={prop} />;
      break;
    case "उद्योग ठाउँसारी सिफारिस":
      displayContent = <UdhyogThauSaari prop={prop} />;
      break;
    case "आधारभूत विद्यालय खोल्न सिफारिस / विद्यालय कक्षा थप गर्ने":
      displayContent = <BasicShcool prop={prop} />;
      break;
    case "जग्गा मूल्यांकन/प्रमाणित":
      displayContent = <LandEvaluation prop={prop} />;
      break;
    case "घरबाटो / चारकिल्ला प्रमाणित / पूर्जामा घर कायम गर्ने सिफारिस":
      displayContent = <GharBato prop={prop} />;
      break;
    case "अशक्त, असहाय तथा अनाथको पालन पोषणको लागि सिफारिस":
      displayContent = <Helpless prop={prop} />;
      break;
    case "आर्थिक अवस्था प्रमाणित":
      displayContent = <EconomicStatus prop={prop} />;
      break;
    case "विद्यालय ठाउँसारी सिफारिस":
      displayContent = <SchoolThauSaari prop={prop} />;
      break;
    case "धारा, विद्युत जडान सिफारिस":
      displayContent = <Dhara prop={prop} />;
      break;
    case "जातीय पहिचान र जातीय सिफारिस":
      displayContent = <Jatiya prop={prop} />;
      break;
    case "संस्था":
      displayContent = <Institute prop={prop} />;
      break;
    default:
      break;
  }
  return (
    <>
      {" "}
      <div className="mukesh">
        <div className="GridContainer">
          <div className="left-section">
            <Applicant
              selection={props.title}
              applicant={props.applicant}
              handleApplicant={props.handleApplicant}
            />
            <ToastContainer />
            {printAction}
            {/* input section */}
            <div className="col">
              <hr />
              <div className="row">
                <div className="col">
                  {" "}
                  <div className="form-floating mb-1 DateRow">
                    <input
                      type="text"
                      className="form-control"
                      id="applicationDate"
                      placeholder="Enter Date"
                      value={date.applicationDate}
                      onChange={(e) =>
                        props.setApplicationDate(isValidDate(e.target.value))
                      }
                    />
                    <label htmlFor="floatingInput">Application Date: </label>
                  </div>
                </div>
                <div className="col">
                  {" "}
                  <DateItem date={props.date} selection={props.title} />
                </div>
              </div>
              <hr />

              <p>Topic Specific Items</p>
              <div className="row ml-5">
                {topicSpecificItems}
                {inputs}
              </div>

              <div className="row">
                <div className="col mr-5">{senderSide}</div>
                <div className="col ml-5">{receiverSide}</div>
              </div>
            </div>
          </div>
          <div className="right-section">
            <div className="col">
              {selectTopic} <br />
              <div id="displayContent">{displayContent}</div>
            </div>
          </div>
          <form action="default" className="mainform" onSubmit={handleSubmit}>
            <input
              type="submit"
              value="Submit"
              align="right"
              disabled={false}
            />
          </form>
        </div>
      </div>
    </>
  );
};
