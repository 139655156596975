import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { userDetails } from "../LoginDetails";
import Heading from "../ParagraphItems/Heading";
import OfficeFooter from "../ParagraphItems/OfficeFooter";
import OfficeInformation from "../ParagraphItems/OfficeInformation";
import SarjinPresent from "../ParagraphItems/SarjinPresent";
import Selection from "../ParagraphItems/Selection";
import Sender from "../ParagraphItems/Sender";
import {
  bold,
  spaces,
  twoNamesDefaults,
  twoNamesSpecificItems,
  underline,
} from "../Variables";
import { getApplicant, officeAddress } from "../Variables/TextVariables";

export default function Institute(prop) {
  const props = prop.prop;
  const details = props.details;
  const [applicantRelation, setApplicantRelation] = useState({});
  const [sarjin, setSarjin] = useState({});
  const selectionTitle = "selectedTitle";

  const selectionTypes = ["संस्था नविकरण", "संस्था दर्ता"];
  const selectedTopic = details[selectionTitle]
    ? details[selectionTitle]
    : selectionTypes[0];
  const selectedIndex = selectionTypes.indexOf(selectedTopic);

  var subject = details[twoNamesSpecificItems[0]];
  if (!subject) subject = twoNamesDefaults[0];
  // console.log(subject, "subject");

  var relationWithApplicant = details[twoNamesSpecificItems[1]] + "को";
  if (!details[twoNamesSpecificItems[1]]) relationWithApplicant = "";
  if (details[twoNamesSpecificItems[1]] === "आफै") relationWithApplicant = "";

  // subject = bold(subject)
  relationWithApplicant = bold(relationWithApplicant);

  const componentRef = props.componentRef;
  return (
    <div>
      {/* <div className='printAction'>
                <ReactToPrint
                    trigger={() => <button className="btn btn-danger" type="submit">Submit</button>}
                    content={() => componentRef.current}
                />
            </div> */}
      <Selection
        selectionTitle={selectionTitle}
        options={selectionTypes}
        details={details}
        detailsUpdater={props.detailsUpdater}
      />

      {spaces(5)}
      <SarjinPresent
        details={details}
        detailsUpdater={props.detailsUpdater}
        setSarjin={setSarjin}
        date={props.date}
      />

      <div className="businessClose" contentEditable="true" ref={componentRef}>
        <OfficeInformation />
        <Heading
          receiver={props.person.receiver}
          topic={selectedTopic + " सिफारिस"}
          date={props.date}
        />

        <br />
        <div className="content" align="justify">
          उपरोक्त सम्बन्धमा {bold(getApplicant())}ले यस {officeAddress()} को{" "}
          {userDetails().office.नाम}मा मिति <b>{props.date.applicationDate}</b>{" "}
          गते {selectedTopic} सिफारस उपलब्ध गरिपाऊँ भनि दिएको निवेदन अनुसार उक्त{" "}
          {bold(selectedTopic)} नियमानुसार गरिदिनुहुन सिफारिस गरिएको व्यहोरा
          अनुरोध छ ।
        </div>
        <br />
        <br />
        <div className="signature" align="right">
          <Sender person={props.person} />
        </div>
      </div>
    </div>
  );
}
