import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, database, signInWithGoogle } from "../ServerWorks/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  DocumentReference,
} from "firebase/firestore";
import { setIsUserInfo, setLoginCalled } from "../Variables/TextVariables";
import Dashboard from "../Dashboard";
import SignupPage from "./signupPage";
import { setuserDetails } from "../LoginDetails";
import Spinners from "../InteractingItems/Spinners";
import Secretariat from "../MayorSecretariat/Secretariat";
// import { setuserDetails } from "../LoginDetails";
// import "./Login.css";
function Login() {
  // console.log("Login Component ..Running")
  setLoginCalled(true);
  const [nextPart, setNextPart] = useState();
  const [signUp, setSignUp] = useState();
  const [show, setShow] = useState(false);
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  // const navigate = useNavigate();
  useEffect(() => {
    console.log("I am inside useEffect");
    setTimeout(() => setShow(true), 3000);
  }, []);
  useEffect(() => {
    console.log("inside login useEffect");
    if (loading) {
      // maybe trigger a loading screen
      console.log("loading");
      return;
    }
    if (user) {
      console.log("user is true");
      const q = query(
        collection(database, "users"),
        where("uid", "==", user.uid)
      );
      getDocs(q).then((result) => {
        if (result.docs.length === 0) {
          console.log("setting user info false");
          setIsUserInfo(false);
          setSignUp(<SignupPage />);
          // await addDoc(collection(database, "users"), {
          //     uid: user.uid,
          //     name: user.displayName,
          //     authProvider: "google",
          //     email: user.email,
          // });
        } else {
          const userData = result.docs[0].data();
          const userId = result.docs[0].id; // Get the document ID
          console.log(result.docs[0].data(), "Data");
          console.log("setting user info true");
          setIsUserInfo(true);
          setuserDetails({ ...userData, id: userId }); // Include the ID in userDetails
          // navigate("/dashboard")
          setNextPart(<Dashboard data={result.docs[0].data()} />);
        }
      });

      // navigate("/dashboard")
    } else {
      console.log("user is not defined");
    }
  }, [user, loading]);
  if (signUp) return signUp;
  if (nextPart) return nextPart;

  return (
    <div className="login">
      <div className="login__container">
        {/* <input
                    type="text"
                    className="login__textBox"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address"
                />
                <input
                    type="password"
                    className="login__textBox"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                /> */}
        {/* <button
                    className="login__btn"
                    onClick={() => signInWithEmailAndPassword(email, password)}
                >
                    Login
                </button> */}
        {
          <div className="align-items-center justify-content-center ">
            {<Spinners />}

            {show && (
              <button
                className="login__btn login__google center"
                onClick={signInWithGoogle}
              >
                Login with Google
              </button>
            )}
            {show && (
              <button
                className="btn btn-primary heading"
                onClick={() => {
                  setNextPart(<Secretariat />);
                }}
              >
                नगर प्रमुख सचिवालय
              </button>
            )}
          </div>
        }

        {/* <div>
                    <Link to="/reset">Forgot Password</Link>
                </div> */}
        {/* <div>
                    Don't have an account? <Link to="/register">Register</Link> now.
                </div> */}
      </div>
    </div>
  );
}
export default Login;
