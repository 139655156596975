import React from "react";
import { userDetails } from "../LoginDetails";
import {
  districts_Eng,
  districts_Nep,
  pradesh_Eng,
  pradesh_Nep,
} from "../Variables";
import OfficeFooter from "./OfficeFooter";
import { currentFiscalYear } from "../Variables/TextVariables";
import nepal_logo from "../Logos/nepal_logo.jpg";
import { getAD } from "../tests";

export default function OfficeInformationEnglish(props) {
  const {
    chalaniName = "Dispatch No.:-",
    patraName = "Reference No.:- ",
    noPatra,
  } = props;
  const patraShankya = `${patraName} ${currentFiscalYear()}`;
  let userDetail = userDetails();
  return (
    <div className="officeInformation">
      <div className="nepalLogo">
        <div className="logo">
          <img src={nepal_logo} alt="nepallogo" />
        </div>
        {props.noChalani ? (
          ""
        ) : (
          <React.Fragment>
            <p>
            {!props.noPatra && patraShankya} <br />
            {chalaniName}
            </p>
          </React.Fragment>
        )}
      </div>
      <div className="HeadingInfo">
        <h2
          style={{
            letterSpacing: "2px", // Adjust the value for double spacing
            textTransform: "uppercase", // Ensures text is displayed in uppercase
          }}
        >
          {userDetail.municipality.engName} {userDetail.municipality.engType}
        </h2>

        {/* <h3>{userDetail.municipality.engName.toUpperCase()} {userDetail.municipality.engType.toUpperCase()}</h3> */}
        <h4>{userDetail.office.engName.toUpperCase()}</h4>

        {/* <h4>{userDetail.office.engName}</h4> */}
        <h5>
          {userDetail.office.engAddress},{/*userDetail.municipality.नाम*/}{" "}
          {districts_Eng[districts_Nep.indexOf(userDetail.district)]}
          <br />
          {pradesh_Eng[pradesh_Nep.indexOf(userDetail.province)]}, Nepal
        </h5>

      </div>
      <div id="DateRow" align="right" className="englishFont englishDate">
        Date: {getAD(props.date.currentDate)}
      </div>
      <hr />
      {!props.headerName ? "" : <h3>{props.headerName}</h3>}
      {props.nofooter ? "" : <OfficeFooter isEnglish={true} />}
    </div>
  );
}
